@import url(https://fonts.googleapis.com/css?family=Roboto:500);
* {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  overflow-x: hidden;
}

p {
  margin: 0;
  padding: 0;
}

.container {
  width: calc(100% - 4em);
  max-width: 1600px;
  margin: 0 auto;
}

#hero {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.centered {
  text-align: center;
}
